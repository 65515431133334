.SFSchoolMain {
  background-color: #2E2E2E;
  color: white;
  padding-bottom: 10vh;
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5vh;
  padding-right: 5vh;
  padding-top: 2vh;
}

.header{
  font-size: calc(20px + 4vmin);
}

.bridge {
  max-width: 15vh;
  margin: 2vh;
}

.projectAbout {
  font-size: calc(1px + 2vmin);
  padding-left: 25vh;
  padding-right: 25vh;
}

.title {
  font-size: calc(15px + 3vmin);
  padding-left: 20vh;
  padding-top: 5vh;
}

.description {
  font-size: calc(2px + 1.5vmin);
  padding-left: 20vh;
  padding-right: 20vh;
}

.teamPhoto {
  max-width: 55%;
  margin-left: 22.5%;
}

.SFDiagram {
  max-width: 75%;
  margin-left: 12.5%;
}

.QuizPage {
  max-width: 70%;
  margin-left: 15%;
}

.AddFeaturesPhotos2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.HomePageSpanish {
  max-width: 60%;
  margin-top: 2vh;
}

.SchoolResults {
  max-width: 25%;
  margin-top: 2vh;
}
