.GifterMain {
  background-color: #2E2E2E;
  color: white;
  padding-bottom: 5vh;
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5vh;
  padding-right: 5vh;
  padding-top: 2vh;
}

.header{
  font-size: calc(20px + 4vmin);
}

.Present {
  max-width: 20vh;
  margin-bottom: 5vh;
}

.firstGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.projectAbout {
  font-size: calc(1px + 2vmin);
  padding-left: 25vh;
}

.textColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.GifterHome {
  max-width: 18.5%;
  margin-right: 15vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}

.GifterMainScreen {
  max-width: 18%;
  margin-left: 15vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}

.secondGroup{
  display: flex;
  flex-direction: row;
}

.title {
  font-size: calc(15px + 3vmin);
  padding-left: 15vh;
}

.description {
  font-size: calc(8px + 1vmin);
  padding-left: 15vh;
  padding-right: 25vh;
}

.AdditionalFeatures {
  margin-top: 8vh;
}

.title2{
  font-size: calc(15px + 3vmin);
  padding-left: 25vh;
}

.description2 {
  font-size: calc(8px + 1vmin);
  padding-left: 25vh;
  padding-right: 25vh;

}

.AddFeaturesPhotos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5vh;
}

.Twillio {
  max-width: 17%;
  margin-left: 10vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}

.Fuzzies {
  max-width: 17%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}
