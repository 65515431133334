.projectContainer{
  text-align: center;
}

.row1 {
  display: flex;
  flex-direction: row;

}

.row2 {
  display: flex;
  flex-direction: row;

}

.tile {
  min-width: 33.33vw;
  min-height: 47vh;
  padding-top: 8vh;
}

.tile1 {
  min-width: 33.33vw;
  min-height: 47vh;
  padding-top: 8vh;
}

.tile-22 {
  min-width: 33.33vw;
  min-height: 47vh;
}
