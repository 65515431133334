.RROMainPage {
  background-color: #2E2E2E;
  color: white;
  min-height: 94vh;
  padding-bottom: 5vh;
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5vh;
  padding-right: 5vh;
  padding-top: 2vh;
}

.header{
  font-size: calc(20px + 4vmin);
}

.BlockM {
  max-width: 20vh;
}

.RossPhoto {
  display: flex;
  justify-content: center;
}

.RossBuilding {
  max-width: 65vw;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.AffinityWallSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AffinityWall {
  max-width: 100vw;
}
