.NeedNewMusicMain {
  background-color: #2E2E2E;
  color: white;
  min-height: 94vh;
  padding-bottom: 10vh;
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5vh;
  padding-right: 5vh;
  padding-top: 2vh;
}

.header{
  font-size: calc(20px + 4vmin);
}

.NeedNewMusicLogo{
  max-width: 20vh;
}

.titleMusic {
  font-size: calc(15px + 3vmin);
  padding-left: 25vh;
  padding-top: 5vh;
}

.musicPhotos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.GenreList {
  max-width: 20vw;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.NewsList {
  max-width: 70vw;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.infoSection {
  margin-top: 5vh;
}

.spotifySection {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.Spotify {
  max-width: 70vw;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Recommendations {
  max-width: 70vw;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 5vh;
}
