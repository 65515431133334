#basic-nav-dropdown {
  position: relative;
  z-index: 100;
}

.home {
  background: url('./photos/thais22.JPG') no-repeat center center fixed;
  background-position: center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 94vh;
  display: flex;
  flex-flow: column wrap;
  text-align: right;
  padding-right: 100px;
  justify-content: center;
}

.name {
  font-size: calc(30px + 6vmin);
  color: white;
  opacity: 0.8;
  flex-grow: 0 !important;
  padding-top: 15%;
}

.bio {
  font-size: calc(15px + 2vmin);
  color: white;
  opacity: 0.7;
  flex-grow: 0 !important;
}

.socialRow {
  align-self: flex-end;
  margin-right: 10vh;
  margin-top: 2vh;
}

.socialLogoL {
  max-height: 10vh;
}

.socialLogo {
  max-height: 10vh;
}

.socialLogoG {
  max-height: 10vh;
}

.email {
  font-size: calc(10px + 1vmin);
  color:white;
  opacity: 0.6;
  margin-right: 9vw;
  margin-top: 1vh;
}
